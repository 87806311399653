import { graphql, useStaticQuery } from "gatsby"
import toPascal from "./toPascal"


export default () => {    
  const data = useStaticQuery(graphql`
  {  
    collection: allDropboxImage(filter: {directory: {eq: "root/collectie"}}) {
      edges {
        node {
          name
          path
          localFile {
            birthtime
            image: childImageSharp {
              thumb: fluid(maxWidth: 270, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    markdown: allDropboxMarkdown(filter: {directory: {eq: "root/collectie"}}) {
      edges {
        node {
          name
          path
          localFile {
            childMarkdownRemark {
              html
              frontmatter {
                title
                price
                size
                sold
              }
            }
          }
        }
      }
    }
  }
  `)
    
    
  const files = data.collection.edges.map(edge => {    
    let {name, path} = edge.node
    const imageName = name.substr(0, name.lastIndexOf('.')) || name;
    const {thumb, full} = edge.node.localFile.image
    // if there is a markdown with same name (without prefix), then add its content
    const markdown = data.markdown.edges.find(edge => {
      const {name} = edge.node
      const markdownName = name.substr(0, name.lastIndexOf('.md'));
      return (imageName === markdownName)
    })
    const info = markdown ? markdown.node.localFile.childMarkdownRemark : null;
    
    if (info && info.frontmatter.title){
      name = info.frontmatter.title
    } else 
    {
      // name = imageName.replace('_', ' ');
      name = imageName.replace(/_/g, ' ');
      name = toPascal(name)
    }

    // const markdown = data.collection.find(item => item.id ===)
    return {name, path, thumb, full, info}
  })
  return files
}