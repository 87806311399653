import React, { useState } from 'react'

import './style.scss'
import styled from 'styled-components'
import Navigation from '../components/navigation'

import withLayout from '../components/layout'
import collection from '../utils/collection'
import Img from "gatsby-image"
import Lightbox from '../components/lightbox/Lightbox';



const Container = styled.div`
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
`

const Painting = styled.div`
    
    display: flex; 
    flex-direction: column;
    width: 30%;
    margin-bottom: 30px;

    @media(max-width: 768px) {
        width: 48%;
    }

    @media(max-width: 500px) {
        width: 100%;
    }
    .paintingWrapper
    {
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
        padding: 25px 25px;
        box-shadow: -1px 0 2px 0 rgba(0,0,0,.2), 1px 0 2px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.4);
        position: relative;
        

        &.sold::after
        {
            content: " ";
            width: 160px;
            height: 100px;
            
            background: transparent url("/images/thumb_overlay_sold.png") no-repeat;
            top: 0px;
            left: 0px;
            position: absolute;
            display: inline-block;
        }


        img {
            object-fit: contain;
            width: 100%;
            height: auto;
        }

    }


    span {
        padding: 0 10px 10px;
        text-align: center;


        &.description p{
            margin: 0;
        }
    }
   
`

const Gallery = () => {
//   const items = Object.keys(publications.items)

// getImages().then((json) => {
//     const items = json.slice(0, 10)
// });
    const items = collection();

    console.log(items)

    const [toggler, setToggler] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    

    const handlePictureClick = (picture, index) => {
        console.log(index)
        setPhotoIndex(index)
        setToggler(true)
    }
    
    
    
    


  return (
    <>
      <header className="small_header">
        <div className="inline">
          <Navigation />
        </div>
      </header>
      <main>
        <section className="info inline">
        <h2>
              Gallery
            </h2>


            <Container>
                {items.map((image, index) => {
                    const info = image.info
                    
                    return (
                        <Painting source={image} key={index}>
                        
                        <div className={`paintingWrapper ${info && info.frontmatter.sold && 'sold'}`} onClick={() => handlePictureClick(image, index)}>
                            <Img fluid={image.thumb} />
                        </div>

                        <span className="title">{image.name}</span>
                        {info && info.frontmatter.price && !info && info.frontmatter.sold && (
                            <span className="price">{info.frontmatter.price}</span>
                        )}
                        <span className="size">{info && info.frontmatter.size}</span>
                        {info && info.html && (
                            <span className="description" dangerouslySetInnerHTML={{__html: info && info.html}}></span>
                        )}
                    </Painting>
                    )    
                })}

                <Lightbox toggler={toggler}
                    onClose={() => setToggler(false)}
                    // items={items}
                    images={items}                    
                    photoIndex={photoIndex}
                    setPhotoIndex={(i) => setPhotoIndex(i)}
                    
                />
            </Container>
        </section>
      </main>
    </>
  )
}

const customProps = {
  localeKey: 'publications', // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(Gallery)
