import React  from 'react'
import ReactLightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
 


const Lightbox = ({toggler, onClose, images, photoIndex, setPhotoIndex}) => {

    
    
    return (
        toggler && (
            <ReactLightbox
              mainSrc={images[photoIndex].full.src}
              nextSrc={images[(photoIndex + 1) % images.length].full.src}
              prevSrc={images[(photoIndex + images.length - 1) % images.length].full.src}
              onCloseRequest={onClose}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
              imageCaption={images[photoIndex].name}
            />
          )
    )
}

export default Lightbox
